/**
 * nCompass: User Idle State Manager
 * @author Miquel Brazil
 */

export default (timestamp = null, config = {}) => ({
	config: {},
	UserPageInteractionManagerName: "ncUserPageInteractionManager",
	UserAppInteractionManagerName: "ncUserAppInteractionManager",
	storageManager:{
        getItem: (key, storage = localStorage) =>
            JSON.parse(storage.getItem(key)),
        setItem: (key, value, storage = localStorage) =>
            storage.setItem(key, JSON.stringify(value)),
        removeItem: (key, storage = localStorage) => storage.removeItem(key),
    },
	modal: null,
	Timers: {
		userActive: {
			id: null,
			name: "userActive",
			duration: 0, // in minutes
			start: null,
			end: null,
			next(parent) {
				let isUserActive = null;
				parent.removeIdleUserDetectionListeners(
					parent.resetUserActiveTimer
				);

				if (parent.storageManager.getItem(parent.UserPageInteractionManagerName, sessionStorage)) {
					console.log(
						parent.storageManager.getItem(parent.UserPageInteractionManagerName, sessionStorage)?.lastEvent,
						parent.Timers.userActive.start
					);
					if (
						parent.storageManager.getItem(parent.UserPageInteractionManagerName, sessionStorage)?.lastEvent <=
						parent.Timers.userActive.start
					) {
						console.log(
							"The last User Event from this Window is the same as the current event. The User is no longer Active here."
						);
						isUserActive = false;
					} else {
						console.log(
							"The last User Event from this Window is before the current event. The User is still Active here."
						);
						isUserActive = true;
					}
				}

				if (parent.storageManager.getItem(parent.UserAppInteractionManagerName)) {
					if (
						parent.storageManager.getItem(parent.UserAppInteractionManagerName)?.lastEvent <=
						parent.Timers.userActive.start
					) {
						console.log(
							"The last User Event for the App in a browser occured before the Current Event. The User is no longer Active anywhere."
						);
						isUserActive = false;
					} else {
						console.log(
							"The last User Event for the App in a browser occured after the Current Event. The User is still Active in another window, tab or client."
						);
						isUserActive = true;
					}
				}

				if (isUserActive) {
					console.log(
						"User is Active in another browser tab or window. Let's extend the current session."
					);
					parent.reset(parent.Timers.userActive);
				} else {
					console.log(
						"The User is no longer Active. Let's start the userIdle timer."
					);

					// Clear the existing userActive timer and its clock before starting the userIdle timer
					parent.clearTimer(parent.Timers.userActive.id);
					parent.clearTimer(parent.Timers.userActive.clock.id);

					console.log(listActiveTimers());
					parent.startTimer(parent.Timers.userIdle);
					console.log(listActiveTimers());
				}

				parent.addIdleUserDetectionListeners(
					parent.resetUserActiveTimer
				);
			},
		},
		userIdle: {
			id: null,
			name: "userIdle",
			duration: 0, // in minutes
			start: null,
			end: null,
			init(parent) {
				//console.log("The userIdle timer is starting up. Starting the User Inform timer too.")
				parent.startTimer(parent.Timers.userInform, this.start, {
					offsetFrom: this,
				});
			},
			next(parent) {
				//console.log("The userIdle timer has ended. Let's log the User out.")
				parent.endSession("timeout");
			},
		},
		userInform: {
			id: null,
			name: "userInform",
			duration: 0.5, // in minutes
			offset: 0, // in minutes
			start: null,
			end: null,
			init(parent) {
				//console.log("The userInform timer is starting up.")
			},
			next(parent) {
				console.log(
					"The userInform timer has ended. Let's show the inactivity modal."
				);
				parent.clearTimer(parent.Timers.userInform.id);
				parent.clearTimer(parent.Timers.userInform.clock.id);

				parent.removeIdleUserDetectionListeners(
					parent.resetUserActiveTimer
				);
				parent.modal.show();
			},
		},
	},
	startTimer(timer, start = parseInt(Date.now() / 1000), options = {}) {
		timer.start = start;
		let duration = 0;

		if (options?.offsetFrom && timer?.offset) {
			console.log(
				"The " +
					timer.name +
					" timer is offset from the " +
					options.offsetFrom?.name +
					" timer."
			);
			console.log(options.offsetFrom?.end);

			// subtract the offset from the start time of the timer it is offset from
			// the end time from the offsetFrom timer is in milliseconds as returned from the getTime() method of the Date object and must be converted to seconds
			// the offset time is in minutes and must be converted to seconds
			// the result is then converted back to milliseconds to be passed to the Date object
			timer.end = new Date(
				(parseInt(options.offsetFrom?.end.getTime() / 1000) -
					timer.offset * 60) *
					1000
			)
			duration = options.offsetFrom?.duration - timer.offset
			console.log(duration)
		} else {
			timer.end = new Date((start + (timer.duration * 60)) * 1000);
			duration = timer.duration;
		}

		// execute any statements that need to be run when the timer starts
		if (timer.init) {
			timer.init(this);
		}

		// setup the "clock" which keeps track of the time remaining
		Object.assign(timer, {
			clock: {
				id: setInterval(
					() => {
						timer.clock.remaining = this.getRemainingTime(
							timer.end
						);

						if (timer.clock.remaining <= 0) {
							console.log("The " + timer.name + " timer has ended.")
							//this.clearTimer(timer.id)
						} else {
							console.log(
								"The " +
									timer.name +
									" timer has " +
									parseInt(timer.clock.remaining / 1000) +
									" seconds left."
							);
						}
					},
					1000,
					timer.name + "Clock"
				),
				remaining: 0, // in milliseconds
			},
		});

		timer.id = setTimeout(
			() => {
				if (timer.next) {
					console.log(
						"Executing the statements in the next() method."
					);
					timer.next(this);
				} else {
					console.log(
						"The " + timer.name + " timer has no next() method."
					);
				}
			},
			(duration * 60) * 1000,
			timer.name
		);
	},
	init() {
		this.config = config;

		// set default values for the timers
		this.Timers.userActive.duration = this.config.UserActiveDuration;
		this.Timers.userIdle.duration = this.config.UserIdleDuration;
		this.Timers.userInform.offset = this.config.UserIdleInformOffset;

		// setup event listeners for User Activity
		this.resetUserActiveTimer = this.reset.bind(
			this,
			this.Timers.userActive
		);

		this.addIdleUserDetectionListeners(this.resetUserActiveTimer);

		this.modal = new Modal(this.$el,{
			closable: false
		});

		this.startTimer(this.Timers.userActive, timestamp);
	},
	addIdleUserDetectionListeners(handler) {
		const events = [
			"mousemove",
			"click",
			"keydown",
			"scroll",
			"touchmove",
		];
		events.forEach((event) => {
			window.addEventListener(event, handler, { passive: true });
		});
	},
	removeIdleUserDetectionListeners(handler) {
		const events = [
			"mousemove",
			"click",
			"keydown",
			"scroll",
			"touchmove",
		];
		events.forEach((event) => {
			window.removeEventListener(event, handler, { passive: true });
		});
	},
	restart() {
		// convenience method to reset the userActive timer and cascade the resets to the rest of the timers
		return new Promise((resolve, reject) => {
			try {
				this.reset(this.Timers.userActive);
				console.log("The userActive timer has been reset.");
				resolve(this);
			} catch (error) {
				reject(error);
			}
		});
	},
	reset(timer) {
		console.log(timer.start);
		console.log("Resetting the " + timer.name + " timer.");

		this.clearTimer(timer.id);
		timer.id = null;
		this.clearTimer(timer.clock.id);
		delete timer.clock;

		console.log(listActiveTimers());

		if (timer.name === "userActive") {
			console.log("User is active. Let's reset the userIdle timer.");
			this.clearTimer(this.Timers.userIdle.id); // Clear userIdle timer when userActive is reset
			this.clearTimer(this.Timers.userInform.id);

			if (
				this.Timers.userIdle.clock &&
				this.Timers.userIdle.clock.id
			) {
				this.clearTimer(this.Timers.userIdle.clock.id);
			}

			if (
				this.Timers.userInform.clock &&
				this.Timers.userInform.clock.id
			) {
				this.clearTimer(this.Timers.userInform.clock.id);
			}
		}

		this.startTimer(timer);

		// update localstorage Global Event time
		let windowMonitor = this.storageManager.getItem(this.UserPageInteractionManagerName, sessionStorage);
		let clientMonitor = this.storageManager.getItem(this.UserAppInteractionManagerName);

		if (windowMonitor) {
			windowMonitor.lastEvent = timer.start; // this will make it equal to the last event that the browser window or tab is aware of
			this.storageManager.setItem(this.UserPageInteractionManagerName, windowMonitor, sessionStorage);
		}

		if (clientMonitor) {
			clientMonitor.lastEvent = timer.start; // this will make it equal to the last event that the server is aware of
			this.storageManager.setItem(this.UserAppInteractionManagerName, clientMonitor);
		}

		console.log(timer.start);
	},
	clearTimer(timerID) {
		if (timerID) {
			clearTimeout(timerID);
			clearInterval(timerID); // Clears both timeout and interval just in case
		}
	},
	setTimeLeft() {
		const diff = this.Timer.end - new Date().getTime();
		this.Timer.timeRemaining = parseInt(diff / 1000);
	},
	getRemainingTime(timerEnd) {
		const now = Date.now();
		let remainingTime = 0;

		if (timerEnd > now) {
			remainingTime = timerEnd - Date.now();
		}

		return remainingTime;
	},
	getTimeLeft() {
		let time = ''

		if (this.getMinutesLeft() > 0) {
			time += `${this.getMinutesLeft()} minute${this.getMinutesLeft() === 1 ? '' : 's'}`
		}

		if (this.getMinutesLeft() > 0 && this.getSecondsLeft() > 0) {
			time += ' and '
		}

		if (this.getSecondsLeft() > 0) {
			time += `${this.getSecondsLeft()} second${this.getSecondsLeft() === 1 ? '' : 's'}`
		}

		return time;
	},
	getMinutesLeft() {
		if (this.Timers.userIdle.clock) {
			return Math.floor(this.Timers.userIdle.clock.remaining / 60000);
		}

		return 0;
	},
	getSecondsLeft() {
		if (this.Timers.userIdle.clock) {
			return parseInt((this.Timers.userIdle.clock.remaining / 1000) % 60);
		}

		return 0;
	},
	getLastGloablEventTime() {},
	extendSession() {
		this.modal.hide();
		this.restart().then((parent) => {
			console.log("Reactivating the User Activity Event Listeners.");
			parent.addIdleUserDetectionListeners(
				parent.resetUserActiveTimer
			);
		});
	},
	endSession(reason = "manual") {
		this.modal.hide();

		this.storageManager.removeItem(this.UserPageInteractionManagerName, sessionStorage);
		this.storageManager.removeItem(this.UserAppInteractionManagerName);

		window.location.href = "/logout?reason=" + reason;
	},
});
