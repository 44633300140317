/**
 * nCompass - client-document-upload-modal.js
 * @author Miquel Brazil
 */

export default (client, provider, types) => ({
    Modal: null,
    Client: client,
    Provider: provider,
    Document: {
        dates: {
            service: null
        },
        category: null,
        type: null,
        typeLabel: null,
        notes: ''
    },
    Types: types,
    Files: [],
    primaryActionEnabled: false,
    init() {
        this.Modal = new Modal(this.$el);
    },
    show() {
        this.Modal.show();
    },
    hide() {
        this.Modal.hide();
        this.reset();
    },
    upload() {
        let form = new FormData();
        this.Files.forEach((file) => { form.append('files[]', file); });

        form.append('document', JSON.stringify(this.Document));
        form.append('client', JSON.stringify(this.Client));
        form.append('provider', JSON.stringify(this.Provider));

        fetch('/api/v0/clients/admin/upload-document', {
			method: 'POST',
			body: form
		}).then(
			(response) => {
                 if (response.ok) {
                     this.hide();
                     window.location.reload();
                 }
             }
        );
    },
    setFile() {
        const files = this.$event.target.files;

        if (files.length > 0) {
            this.Files = [...files];
        } else {
            this.Files = [];
        }
    },
    updateForm() {
        this.primaryActionEnabled = this.enablePrimaryAction();
        return true;
    },
    enablePrimaryAction() {
        const invalidValues = [null, 0, '0', '', 'undefined']

        if (invalidValues.includes(this.Document.dates.service)) {
            return  false
        }

        if (invalidValues.includes(this.Document.category)) {
            return false
        }

        if (invalidValues.includes(this.Document.type)) {
            return false
        }

        /**
         * If the Document Type is "Other" and the "Note" field has no information
         * then DO NOT activate the Primary Action button
         */
        if (!invalidValues.includes(this.Document.typeLabel)
            && typeof this.Document.typeLabel === 'string'
            && this.Document.typeLabel.toLowerCase() === 'other'
            && invalidValues.includes(this.Document.notes)
        ){
            console.warn('The "Note" field has no information when the Document Type is "Other"')
            return false
        }

        if (this.Files.length === 0) {
            return false
        }

        return true
    },
    isPrimaryActionEnabled() {
        return this.primaryActionEnabled
    },
    isPrimaryActionDisabled() {
        return !this.primaryActionEnabled
    },
    reset() {
        this.Document.dates.service = null;
        this.Document.category = null;
        this.Document.type = null
        this.Document.typeLabel = null
        this.Document.notes = '';
    }
})
